import {
    NOTIFICATION_TEXT_INVALID_END_TIME_RANGE,
    NOTIFICATION_TEXT_INVALID_START_TIME_RANGE,
    NOTIFICATION_TEXT_REQUIRED_END_TIME,
    NOTIFICATION_TEXT_REQUIRED_START_TIME,
} from 'constants/notificationsText';
import {
    PROPERTY_CATEGORY,
    PROPERTY_COACH,
    PROPERTY_COURT_TYPE,
    PROPERTY_DAY,
    PROPERTY_GROUP,
} from 'constants/propertyNames';

import { PriceTypeT } from 'types/PriceType';
import {
    array, object, string,
} from 'yup';

export type PriceFormFields = {
    days: number[];
    time: Array<{
        timeStart: string;
        timeEnd: string;
    }>;
    categoryIds: string[];
    courtTypeIds: string[];
    coachCategoryIds: string[];
    groupViewIds: string[];
};

export const PRICE_DEFAULT_VALUES: PriceFormFields = {
    days: [],
    time: [
        {
            timeStart: '',
            timeEnd: '',
        },
    ],
    categoryIds: [],
    courtTypeIds: [],
    coachCategoryIds: [],
    groupViewIds: [],
};

export enum PRICE_FIELDS_KEYS {
    days = 'days',
    timeStart = 'timeStart',
    timeEnd = 'timeEnd',
    categoryIds = 'categoryIds',
    courtTypeIds = 'courtTypeIds',
    coachCategoryIds = 'coachCategoryIds',
    groupViewIds = 'groupViewIds',
}

export const PRICE_LABEL_DATA = {
    days: PROPERTY_DAY,
    timeStart: 'Время начала',
    timeEnd: 'Время окончания',
    categoryIds: PROPERTY_CATEGORY,
    courtTypeIds: PROPERTY_COURT_TYPE,
    coachCategoryIds: PROPERTY_COACH,
    groupViewIds: PROPERTY_GROUP,
};

export const getPriceValidationSchema = (activePriceType: PriceTypeT | undefined) => (
    object().shape({
        days: array().min(1).required(),
        time: array().of(object().shape({
            timeStart: string()
                .required(NOTIFICATION_TEXT_REQUIRED_START_TIME)
                .test('isLarger', NOTIFICATION_TEXT_INVALID_START_TIME_RANGE, (value: any, testContext) => {
                    return testContext.parent.timeEnd > value;
                }),
            timeEnd: string()
                .required(NOTIFICATION_TEXT_REQUIRED_END_TIME)
                .test('isLarger', NOTIFICATION_TEXT_INVALID_END_TIME_RANGE, (value: any, testContext) => {
                    return testContext.parent.timeStart < value;
                }),
        })),
        categoryIds: array().min(1).required(),
        courtTypeIds: array().min(1).required(),
        coachCategoryIds: activePriceType?.isCoachAvailable ? array().min(1).required() : array(),
        groupViewIds: activePriceType?.isGroupAvailable ? array().min(1).required() : array(),
    }).required()
);
