import { HOUR, RUB_SYMBOL } from 'constants/currency';
import {
    PROPERTY_MEASUREMENT_UNIT,
    PROPERTY_RETURN_FOR_CANCEL,
    PROPERTY_RETURN_FOR_SELL,
    PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS_HELPER_TEXT, PROPERTY_VALUE,
} from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import React, { FC, useEffect } from 'react';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import { Slider } from 'page/Price/components/Slider';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import TextField from 'shared/component/Form/TextField';
import ToggleButton from 'shared/component/ToggleButton';
import { parseSelectOptions } from 'shared/helper/common/parseSelectOptions';
import { getPercentAndFiatOptions } from 'shared/utils/getPercentAndFiatOptions';
import { useStore } from 'store';

import { YES_AND_NO_BUTTONS } from './domain/ToggleButtonValues';
import {
    UPDATE_PRICE_RATE_DEFAULT_VALUES, UPDATE_PRICE_RATE_FIELDS_KEYS, UPDATE_PRICE_RATE_LABEL_DATA,
    UpdatePriceRateFormFields,
} from './formData';

type FieldProps = UseControllerProps & {
    onChange: (value: any) => void;
    onBlur: () => void;
    value: any;
    name: string;
    ref: React.Ref<any>;
};

type PropsT = {
    control: Control<any>;
    setValue: Function;
    resetTrigger: boolean;
    resetField: Function;
    timePeriodsLoading: boolean;
    coachCategoryIds: FieldProps;
    groupViewIds: FieldProps;
    courtTypeIds: FieldProps;
    categoryIds: FieldProps;
    days: FieldProps;
}

const UpdatePriceRateModal: FC<PropsT> = observer((props) => {
    const {
        control, setValue, resetTrigger, timePeriodsLoading, coachCategoryIds, groupViewIds, courtTypeIds, categoryIds, days,
    } = props;
    const {
        priceSalesRounds,
        groupViews,
        coachCategories,
        timePeriods: timePeriodsData,
    } = useStore('updatePriceRateModal');
    const {
        activePriceType,
    } = useStore('price');
    const { serviceApp } = useStore('serviceApp');
    const { getFilterOption } = useStore('filterOptions');
    const categories = getFilterOption('serviceCategory');
    const courts = getFilterOption('courtType');
    const { field: shareOfPaymentBonusStatus } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.shareOfPaymentBonusStatus });
    const { field: shareOfPaymentFiatStatus } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.shareOfPaymentFiatStatus });
    const { field: priceSalesRoundIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.priceSalesRoundIds });
    const { field: returnCurrency } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.returnCurrency });
    const { field: transferable } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.transferable });
    const { field: saleCurrency } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.saleCurrency });
    const { field: cancellable } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.cancellable });
    const { field: timePeriods } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.timePeriods });

    const priceSalesRoundOptions = priceSalesRounds.map((item) => (
        {
            label: item.title,
            value: item.id,
        }
    ));

    const priceTimePeriods = timePeriodsData.map((item) => (
        {
            label: `${item.timeStart} — ${item.timeEnd}`,
            value: `${item.timeStart}-${item.timeEnd}`,
        }
    ));

    const setReturnCurrency = (value: string) => {
        setValue(returnCurrency.name, value);
    };

    const setCancelCurrency = (value: string) => {
        setValue(saleCurrency.name, value);
    };

    const setTransferable = (value: string | null) => {
        setValue(transferable.name, value);
    };

    const setCancellable = (value: string | null) => {
        setValue(cancellable.name, value);
    };

    const handleChangeFiatStatus = (value: boolean) => {
        setValue(shareOfPaymentFiatStatus.name, value);
    };

    const handleChangeBonusStatus = (value: boolean) => {
        setValue(shareOfPaymentBonusStatus.name, value);
    };

    useEffect(() => {
        setTransferable(null);
        setCancellable(null);
    }, [resetTrigger]);

    return (
        <Grid2 container direction="column" rowSpacing={ 2 }>
            <Grid2 container direction="column" rowSpacing={ 1 }>
                <Typography variant="h6">Настройка параметров отбора строк прайса</Typography>
                <Grid2>
                    <MultipleSelect
                        options={ priceSalesRoundOptions }
                        control={ control }
                        label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.priceSalesRoundIds as keyof UpdatePriceRateFormFields] }
                        type={ typeof UPDATE_PRICE_RATE_DEFAULT_VALUES[UPDATE_PRICE_RATE_FIELDS_KEYS.priceSalesRoundIds as keyof UpdatePriceRateFormFields] }
                        name={ UPDATE_PRICE_RATE_FIELDS_KEYS.priceSalesRoundIds }
                        selectedValues={ priceSalesRoundIds.value }
                        size="small"
                        labelSize={ 12 }
                    />
                </Grid2>
                <Grid2>
                    <MultipleSelect
                        control={ control }
                        options={ categories }
                        name={ UPDATE_PRICE_RATE_FIELDS_KEYS.categoryIds }
                        type={ typeof UPDATE_PRICE_RATE_DEFAULT_VALUES[UPDATE_PRICE_RATE_FIELDS_KEYS.categoryIds as keyof UpdatePriceRateFormFields] }
                        label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.categoryIds as keyof UpdatePriceRateFormFields] }
                        size="small"
                        selectedValues={ categoryIds.value }
                        labelSize={ 12 }
                    />
                </Grid2>
                <Grid2>
                    <MultipleSelect
                        control={ control }
                        options={ courts }
                        name={ UPDATE_PRICE_RATE_FIELDS_KEYS.courtTypeIds }
                        type={ typeof UPDATE_PRICE_RATE_DEFAULT_VALUES[UPDATE_PRICE_RATE_FIELDS_KEYS.courtTypeIds as keyof UpdatePriceRateFormFields] }
                        label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.courtTypeIds as keyof UpdatePriceRateFormFields] }
                        size="small"
                        selectedValues={ courtTypeIds.value }
                        labelSize={ 12 }
                    />
                </Grid2>
                <Grid2>
                    <MultipleSelect
                        control={ control }
                        options={ WEEK_DAYS_VALUE_OPTIONS }
                        name={ UPDATE_PRICE_RATE_FIELDS_KEYS.days }
                        type={ typeof UPDATE_PRICE_RATE_DEFAULT_VALUES[UPDATE_PRICE_RATE_FIELDS_KEYS.days as keyof UpdatePriceRateFormFields] }
                        label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.days as keyof UpdatePriceRateFormFields] }
                        size="small"
                        selectedValues={ days.value }
                        labelSize={ 12 }
                    />
                </Grid2>
                {
                    activePriceType?.isCoachAvailable && (
                        <Grid2>
                            <MultipleSelect
                                control={ control }
                                options={ parseSelectOptions(coachCategories) }
                                name={ UPDATE_PRICE_RATE_FIELDS_KEYS.coachCategoryIds }
                                label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.coachCategoryIds] }
                                selectedValues={ coachCategoryIds.value }
                                size="small"
                                labelSize={ 12 }
                            />
                        </Grid2>
                    )
                }
                {
                    activePriceType?.isGroupAvailable && (
                        <Grid2>
                            <MultipleSelect
                                control={ control }
                                options={ parseSelectOptions(groupViews) }
                                name={ UPDATE_PRICE_RATE_FIELDS_KEYS.groupViewIds }
                                label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.groupViewIds] }
                                selectedValues={ groupViewIds.value }
                                size="small"
                                labelSize={ 12 }
                            />
                        </Grid2>
                    )
                }
                <Grid2 container columnSpacing={ 2 }>
                    <Grid2 xs={ 12 }>
                        <MultipleSelect
                            control={ control }
                            options={ priceTimePeriods }
                            loading={ timePeriodsLoading }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.timePeriods }
                            type={ typeof UPDATE_PRICE_RATE_DEFAULT_VALUES[UPDATE_PRICE_RATE_FIELDS_KEYS.timePeriods as keyof UpdatePriceRateFormFields] }
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.timePeriods as keyof UpdatePriceRateFormFields] }
                            selectedValues={ timePeriods.value }
                            size="small"
                            labelSize={ 12 }
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 container direction="column" rowSpacing={ 1 }>
                <Typography variant="h6">Настройка значений тарифа</Typography>
                <Grid2 container columnSpacing={ 2 }>
                    <Grid2 xs={ 2 }>
                        <TextField
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.saleRate }
                            type="number"
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.saleRate as keyof UpdatePriceRateFormFields] }
                            size="small"
                            customError={ true }
                            inputAdornment={ RUB_SYMBOL }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 2 }>
                        <TextField
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.minRate }
                            type="number"
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.minRate as keyof UpdatePriceRateFormFields] }
                            size="small"
                            customError={ true }
                            inputAdornment={ RUB_SYMBOL }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 2 }>
                        <TextField
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.returnRate }
                            type="number"
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.returnRate as keyof UpdatePriceRateFormFields] }
                            size="small"
                            customError={ true }
                            inputAdornment={ RUB_SYMBOL }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <Slider
                            label={ `${UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.shareOfPaymentFiat as keyof UpdatePriceRateFormFields]} ${serviceApp.fiatCurrencyTitle}` }
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.shareOfPaymentFiat }
                            disabled={ shareOfPaymentFiatStatus.value }
                            setDisabled={ handleChangeFiatStatus }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <Slider
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.shareOfPaymentBonus as keyof UpdatePriceRateFormFields] }
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.shareOfPaymentBonus }
                            disabled={ shareOfPaymentBonusStatus.value }
                            setDisabled={ handleChangeBonusStatus }
                            labelSize={ 12 }
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container columnSpacing={ 2 } alignItems="center">
                    <Grid2 xs={ 2 }>
                        <TextField
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.transferDisabledBeforeHours }
                            type="number"
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.transferDisabledBeforeHours as keyof UpdatePriceRateFormFields] }
                            auxiliaryPopoverText={ PROPERTY_TRANSFER_DISABLED_BEFORE_HOURS_HELPER_TEXT }
                            auxiliaryPopoverIconSize="small"
                            size="small"
                            customError={ true }
                            inputAdornment={ HOUR }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 2 }>
                        <TextField
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.backTransferCost }
                            type="number"
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.backTransferCost as keyof UpdatePriceRateFormFields] }
                            size="small"
                            customError={ true }
                            inputAdornment={ RUB_SYMBOL }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 2 }>
                        <TextField
                            control={ control }
                            name={ UPDATE_PRICE_RATE_FIELDS_KEYS.forwardTransferCost }
                            type="number"
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.forwardTransferCost as keyof UpdatePriceRateFormFields] }
                            size="small"
                            customError={ true }
                            inputAdornment={ RUB_SYMBOL }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <ToggleButton
                            buttons={ YES_AND_NO_BUTTONS }
                            formAlignment={ transferable.value }
                            setFormAlignment={ setTransferable }
                            empty={ true }
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.transferable as keyof UpdatePriceRateFormFields] }
                            fullWidth={ true }
                            labelSize={ 12 }
                        />
                    </Grid2>
                    <Grid2 xs={ 3 }>
                        <ToggleButton
                            buttons={ YES_AND_NO_BUTTONS }
                            formAlignment={ cancellable.value }
                            setFormAlignment={ setCancellable }
                            empty={ true }
                            label={ UPDATE_PRICE_RATE_LABEL_DATA[UPDATE_PRICE_RATE_FIELDS_KEYS.cancellable as keyof UpdatePriceRateFormFields] }
                            fullWidth={ true }
                            labelSize={ 12 }
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 xs={ 12 } container>
                <Grid2 container direction="column" xs={ 6 } spacing={ 2 }>
                    <Grid2 xs={ 12 }>
                        <Typography sx={ { fontSize: '18px' } } variant="h6">Сколько получит испольнитель при продаже</Typography>
                    </Grid2>
                    <Grid2 container xs={ 12 }>
                        <Grid2 xs={ 4 }>
                            <TextField
                                control={ control }
                                name={ UPDATE_PRICE_RATE_FIELDS_KEYS.performerSaleIncomeValue }
                                type="number"
                                size="small"
                                label={ PROPERTY_VALUE }
                                inputAdornment={ getPercentAndFiatOptions(serviceApp.fiatCurrencyTitle).find((item) => item.value === saleCurrency.value)?.adornment }
                                labelSize={ 12 }
                            />
                        </Grid2>
                        <Grid2 xs={ 6 }>
                            <ToggleButton
                                buttons={ getPercentAndFiatOptions(serviceApp.fiatCurrencyTitle) }
                                formAlignment={ saleCurrency.value }
                                setFormAlignment={ setCancelCurrency }
                                auxiliaryPopoverText={ PROPERTY_RETURN_FOR_SELL }
                                auxiliaryPopoverIconSize="small"
                                label={ PROPERTY_MEASUREMENT_UNIT }
                                fullWidth={ true }
                                labelSize={ 12 }
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 container direction="column" xs={ 6 } spacing={ 2 }>
                    <Grid2 xs={ 12 }>
                        <Typography sx={ { fontSize: '18px' } } variant="h6">Сколько вернет испольнитель при отмене</Typography>
                    </Grid2>
                    <Grid2 container xs={ 12 }>
                        <Grid2 xs={ 4 }>
                            <TextField
                                control={ control }
                                name={ UPDATE_PRICE_RATE_FIELDS_KEYS.performerReturnIncomeValue }
                                type="number"
                                size="small"
                                label={ PROPERTY_VALUE }
                                inputAdornment={ getPercentAndFiatOptions(serviceApp.fiatCurrencyTitle).find((item) => item.value === returnCurrency.value)?.adornment }
                                labelSize={ 12 }
                            />
                        </Grid2>
                        <Grid2 xs={ 6 }>
                            <ToggleButton
                                buttons={ getPercentAndFiatOptions(serviceApp.fiatCurrencyTitle) }
                                formAlignment={ returnCurrency.value }
                                setFormAlignment={ setReturnCurrency }
                                auxiliaryPopoverText={ PROPERTY_RETURN_FOR_CANCEL }
                                auxiliaryPopoverIconSize="small"
                                label={ PROPERTY_MEASUREMENT_UNIT }
                                fullWidth={ true }
                                labelSize={ 12 }
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    );
});

export default UpdatePriceRateModal;
