import { TIME_23_HOURS_30_MINUTE_15_STEP, TIME_23_HOURS_30_MINUTE_30_STEP } from 'constants/hours';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import React, { FC, useState } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import { TIME_STEPS } from 'page/Price/domain/timeSteps';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import Select from 'shared/component/Form/Select';
import ToggleButton from 'shared/component/ToggleButton';
import { parseSelectOptions } from 'shared/helper/common/parseSelectOptions';
import { useStore } from 'store';

import { UPDATE_PRICE_RATE_FIELDS_KEYS } from './UpdatePriceRate/formData';
import { PRICE_FIELDS_KEYS, PRICE_LABEL_DATA, PriceFormFields } from './formData';

type PropsT = {
    control: Control<PriceFormFields>;
};

const PriceForm: FC<PropsT> = observer((props) => {
    const { control } = props;
    const { coachCategories, groupViews } = useStore('createPriceModal');
    const { activePriceType } = useStore('price');
    const { getFilterOption } = useStore('filterOptions');
    const categories = getFilterOption('serviceCategory');
    const courts = getFilterOption('courtType');
    const { fields, append, remove } = useFieldArray<PriceFormFields>({
        control,
        name: 'time',
    });
    const { field: courtTypeIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.courtTypeIds });
    const { field: categoryIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.categoryIds });
    const { field: days } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.days });
    const { field: coachCategoryIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.coachCategoryIds });
    const { field: groupViewIds } = useController({ control, name: UPDATE_PRICE_RATE_FIELDS_KEYS.groupViewIds });
    const [timeStep, setTimeStep] = useState<'15' | '30'>('30');

    return (
        <Grid2 container direction="column" spacing={ 1 }>
            <Grid2>
                <MultipleSelect
                    options={ categories }
                    name={ PRICE_FIELDS_KEYS.categoryIds as keyof PriceFormFields }
                    control={ control }
                    size="small"
                    selectedValues={ categoryIds.value }
                    label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.categoryIds] }
                />
            </Grid2>
            <Grid2>
                <MultipleSelect
                    options={ courts }
                    name={ PRICE_FIELDS_KEYS.courtTypeIds as keyof PriceFormFields }
                    control={ control }
                    size="small"
                    selectedValues={ courtTypeIds.value }
                    label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.courtTypeIds] }
                />
            </Grid2>
            <Grid2>
                <MultipleSelect
                    options={ WEEK_DAYS_VALUE_OPTIONS }
                    name={ PRICE_FIELDS_KEYS.days as keyof PriceFormFields }
                    control={ control }
                    label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.days] }
                    size="small"
                    selectedValues={ days.value }
                />
            </Grid2>
            {
                activePriceType?.isCoachAvailable && (
                    <Grid2>
                        <MultipleSelect
                            options={ parseSelectOptions(coachCategories) }
                            name={ PRICE_FIELDS_KEYS.coachCategoryIds }
                            control={ control }
                            label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.coachCategoryIds] }
                            size="small"
                            selectedValues={ coachCategoryIds.value }
                        />
                    </Grid2>
                )
            }
            {
                activePriceType?.isGroupAvailable && (
                    <Grid2>
                        <MultipleSelect
                            control={ control }
                            options={ parseSelectOptions(groupViews) }
                            name={ PRICE_FIELDS_KEYS.groupViewIds }
                            label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.groupViewIds] }
                            size="small"
                            selectedValues={ groupViewIds.value }
                        />
                    </Grid2>
                )
            }
            <Grid2 container spacing={ 1 } xs={ 12 }>
                <Grid2 xs={ 12 }>
                    <Box sx={ { display: 'flex', alignItems: 'center' } }>
                        <Typography variant="h6">
                            Временные промежутки начала/окончания занятия с шагом
                        </Typography>
                        <Box sx={ { display: 'flex', alignItems: 'center', margin: '0 12px' } }>
                            <ToggleButton
                                formAlignment={ timeStep }
                                setFormAlignment={ setTimeStep }
                                buttons={ TIME_STEPS }
                            />
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
            {
                fields.map((item, index) => (
                    <Grid2 container spacing={ 2 } xs={ 12 } alignItems="flex-start" key={ item.id }>
                        <Grid2 xs={ 5.3 }>
                            <Select
                                options={ timeStep === '30' ? TIME_23_HOURS_30_MINUTE_30_STEP : TIME_23_HOURS_30_MINUTE_15_STEP }
                                name={ `time.${index}.timeStart` }
                                control={ control }
                                label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.timeStart] }
                                empty={ true }
                                customError
                            />
                        </Grid2>
                        <Grid2 xs={ 5.3 }>
                            <Select
                                options={ timeStep === '30' ? TIME_23_HOURS_30_MINUTE_30_STEP : TIME_23_HOURS_30_MINUTE_15_STEP }
                                name={ `time.${index}.timeEnd` }
                                control={ control }
                                label={ PRICE_LABEL_DATA[PRICE_FIELDS_KEYS.timeEnd] }
                                empty={ true }
                                customError
                            />
                        </Grid2>
                        <Grid2 xs={ 1.4 } sx={ { marginTop: '23px' } }>
                            {
                                index === 0 ? (
                                    <IconButton
                                        color="info"
                                        onClick={ () => append({ timeStart: '', timeEnd: '' }) }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                ) : (
                                    <Box sx={ { display: 'flex', width: '100%', justifyContent: 'space-between' } }>
                                        <IconButton
                                            color="info"
                                            onClick={ () => append({ timeStart: '', timeEnd: '' }) }
                                        >
                                            <AddIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={ () => remove(index) }
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </Box>
                                )
                            }
                        </Grid2>
                    </Grid2>
                ))
            }
        </Grid2>
    );
});

export default PriceForm;
